import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import {
  Avatar,
  Box,
  ButtonGroup,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material'
import GenericPage from '@templates/Generic/Page'
import useApi, { TUseApiHook } from '@hooks/useApi'
import useLicense from '@hooks/useLicense'
import {
  EmailResponse,
  getAlertsEmail,
  getAlertsSyslog,
  getUsers,
  SyslogResponse,
  UsersResponse
} from '@utils/api'
import {
  NotificationImportant,
  People,
  ShoppingCart,
  Subject
} from '@mui/icons-material'
import { Card, KeyValueTable, Button } from '@components'
import moment from 'moment'
import { mergeNotificationTypes } from '@utils/notifications'
import { PlaceholderListItem } from '@components/Placeholder'
import { Skeleton } from '@mui/material'
import { Link } from 'gatsby-theme-material-ui'

const SettingsPage = () => {
  const { license } = useLicense()

  console.log('license', license)

  const hasLicense = license && license.customer.name !== 'Unlicensed'

  const usersApi = useApi({ apiMethod: getUsers })
  const emailApi = useApi<EmailResponse>({
    apiMethod: getAlertsEmail
  })

  const syslogApi = useApi<SyslogResponse>({
    apiMethod: getAlertsSyslog
  })

  return (
    <GenericPage
      title="Settings"
      maxWidth="md"
      breadcrumbs={[
        {
          title: 'Settings'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            data-cy="viewLicense"
            endIcon={<ShoppingCart />}
            to="/settings/license/"
          >
            {hasLicense ? 'View License' : 'Add License'}
          </Button>
          <Button
            data-cy="viewUsers"
            endIcon={<People />}
            to="/settings/users/"
          >
            View Users
          </Button>
          <Button
            data-cy="viewAuditLogs"
            endIcon={<Subject />}
            to="/settings/audit-log/"
          >
            Audit Logs
          </Button>
          <Button
            data-cy="viewNotifications"
            endIcon={<NotificationImportant />}
            to="/settings/notifications/"
          >
            Notifications
          </Button>
        </ButtonGroup>
      }
    >
      <Grid container spacing={4} alignContent="flex-start">
        <Grid xs={12} sm={8} item>
          <SettingsPageUsers usersApi={usersApi} />
        </Grid>
        <Grid xs={12} sm={4} item style={{ alignSelf: 'flex-start' }}>
          <SettingsPageLicense />
        </Grid>
      </Grid>
      <Box p={1.5} />

      <Grid
        container
        spacing={4}
        alignItems="stretch"
        alignContent="stretch"
        style={{ flexGrow: 1, alignSelf: 'stretch' }}
      >
        <Grid xs={12} item>
          <SettingsPageNotifications
            emailApi={emailApi}
            syslogApi={syslogApi}
          />
        </Grid>
      </Grid>
    </GenericPage>
  )
}

const SettingsPageLicense = () => {
  const { isUnlicensed, loading, license, error } = useLicense()

  return (
    <Link to="/settings/license/" sx={{ textDecoration: 'none' }}>
      <Card
        color={isUnlicensed && !loading ? 'error' : 'default'}
        heading="License"
        headingButton={
          <Button
            size="small"
            variant={isUnlicensed ? 'contained' : 'outlined'}
            color={isUnlicensed ? 'primary' : 'inherit'}
          >
            {isUnlicensed ? 'Add' : 'Update'}
          </Button>
        }
      >
        {isUnlicensed ? (
          <Box p={2} data-cy="licenseStatus">
            <Typography>
              {
                'No valid license found. Please install a valid lic…ww.sandflysecurity.com to obtain a trial license.'
              }
            </Typography>
          </Box>
        ) : (
          <>
            <Box padding={2} data-cy="licenseStatus">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                {loading || !license ? (
                  <Grid item>
                    <Avatar
                      sx={{
                        m: '0 auto',
                        mb: 1,
                        fontSize: '3rem',
                        width: (theme) => theme.spacing(10),
                        height: (theme) => theme.spacing(10),
                        bgcolor: 'primary.main',
                        color: 'common.white'
                      }}
                    >
                      {'?'}
                    </Avatar>
                    <Typography variant="subtitle1" align="center">
                      {<Skeleton width="150" />}
                    </Typography>

                    <Typography variant="body2" align="center">
                      <strong>Website: </strong>
                      {
                        <Skeleton
                          style={{ display: 'inline-block', width: 80 }}
                          width="80"
                        />
                      }
                    </Typography>
                    <Typography variant="body2" align="center">
                      <strong>Email: </strong>
                      {
                        <Skeleton
                          style={{ display: 'inline-block', width: 80 }}
                          width="80"
                        />
                      }
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item>
                    <Avatar
                      sx={{
                        m: '0 auto',
                        mb: 1,
                        fontSize: '3rem',
                        width: (theme) => theme.spacing(10),
                        height: (theme) => theme.spacing(10),
                        bgcolor: 'primary.main',
                        color: 'common.white',
                        textDecoration: 'none'
                      }}
                    >
                      {(license &&
                        license.customer.name.charAt(0).toUpperCase()) ||
                        ''}
                    </Avatar>

                    <Typography
                      variant="subtitle1"
                      align="center"
                    >{`${license.customer.name}`}</Typography>

                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ wordBreak: 'break-word' }}
                    >
                      <strong>Website: </strong>
                      {` ${license.customer.website}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ wordBreak: 'break-word' }}
                    >
                      <strong>Email: </strong>
                      {` ${license.customer.email}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Divider />
            <KeyValueTable
              loading={loading}
              dataPoints={[
                ['Host limit', license && license.limits.hosts],
                [
                  'Issued',
                  moment(license && license.date.issued).format('D MMM YYYY')
                ],
                [
                  'Expires',
                  moment(license && license.date.expiry).format('D MMM YYYY')
                ]
              ]}
            />
          </>
        )}
      </Card>
    </Link>
  )
}

const SettingsPageUsers = ({
  usersApi
}: {
  usersApi: TUseApiHook<UsersResponse>
}) => {
  const users = usersApi?.response?.users || []

  return (
    <Card
      heading="User Management"
      headingButton={
        <Button
          to="/settings/users/"
          size="small"
          variant="outlined"
          color="inherit"
        >
          Manage
        </Button>
      }
      sx={{ height: '100%' }}
    >
      <List sx={{ py: 0 }}>
        {!usersApi.response && (
          <>
            <PlaceholderListItem />
            <PlaceholderListItem />
          </>
        )}
        {users.map((user, index) => {
          return (
            <ListItem
              key={user.username}
              button
              divider={index + 1 < users.length}
              onClick={() => navigate(`/settings/users/edit/${user.username}`)}
            >
              <ListItemAvatar>
                <Avatar>{user.full_name.charAt(0).toUpperCase() || ''}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                primary={`${user.username}${
                  user.full_name && ` (${user.full_name})`
                }`}
                secondary={`Role: ${user.roles.join(', ')}`}
              />
              {console.log('user.last_login_date', user.last_login_date)}
              <ListItemText
                sx={{
                  minWidth: '45%',
                  textAlign: 'right',
                  alignSelf: 'start',
                  my: 0.75
                }}
              >
                <strong>Last Login: </strong>
                {user.last_login_date !== null
                  ? `${moment(user.last_login_date).fromNow()}`
                  : 'never'}
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
    </Card>
  )
}

const SettingsPageNotifications = ({
  emailApi,
  syslogApi
}: {
  emailApi: TUseApiHook<EmailResponse>
  syslogApi: TUseApiHook<SyslogResponse>
}) => {
  const alerts = mergeNotificationTypes({
    emails: emailApi?.response?.data || [],
    syslogs: syslogApi?.response?.data || []
  })

  return (
    <Card
      heading="Alerts Management"
      headingButton={
        <Button
          to="/settings/notifications/"
          size="small"
          variant="outlined"
          color="inherit"
        >
          Manage
        </Button>
      }
      sx={{ minHeight: 100 }}
    >
      <List>
        {alerts.map((item, index) => {
          return (
            <ListItem
              key={`${index}-${item.primaryText}`}
              divider={index + 1 < alerts.length}
            >
              <ListItemAvatar>
                <Avatar>{item.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.primaryText}
                secondary={item.secondaryText}
              />
            </ListItem>
          )
        })}
        {!emailApi.response || (!syslogApi.response && <PlaceholderListItem />)}
      </List>
    </Card>
  )
}

export default SettingsPage
